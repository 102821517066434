// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-contato-index-js": () => import("./../src/pages/contato/index.js" /* webpackChunkName: "component---src-pages-contato-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manutencao-js": () => import("./../src/pages/manutencao.js" /* webpackChunkName: "component---src-pages-manutencao-js" */),
  "component---src-pages-produtos-index-js": () => import("./../src/pages/produtos/index.js" /* webpackChunkName: "component---src-pages-produtos-index-js" */),
  "component---src-pages-produtos-ordenhadeira-balde-ao-pe-index-js": () => import("./../src/pages/produtos/ordenhadeira-balde-ao-pe/index.js" /* webpackChunkName: "component---src-pages-produtos-ordenhadeira-balde-ao-pe-index-js" */),
  "component---src-pages-produtos-ordenhadeira-canalizada-index-js": () => import("./../src/pages/produtos/ordenhadeira-canalizada/index.js" /* webpackChunkName: "component---src-pages-produtos-ordenhadeira-canalizada-index-js" */),
  "component---src-pages-produtos-ordenhadeira-movel-index-js": () => import("./../src/pages/produtos/ordenhadeira-movel/index.js" /* webpackChunkName: "component---src-pages-produtos-ordenhadeira-movel-index-js" */),
  "component---src-pages-produtos-pecas-e-acessorios-index-js": () => import("./../src/pages/produtos/pecas-e-acessorios/index.js" /* webpackChunkName: "component---src-pages-produtos-pecas-e-acessorios-index-js" */),
  "component---src-pages-produtos-produtos-de-higiene-index-js": () => import("./../src/pages/produtos/produtos-de-higiene/index.js" /* webpackChunkName: "component---src-pages-produtos-produtos-de-higiene-index-js" */),
  "component---src-pages-produtos-sistema-inteligente-de-ordenha-index-js": () => import("./../src/pages/produtos/sistema-inteligente-de-ordenha/index.js" /* webpackChunkName: "component---src-pages-produtos-sistema-inteligente-de-ordenha-index-js" */),
  "component---src-pages-servicos-index-js": () => import("./../src/pages/servicos/index.js" /* webpackChunkName: "component---src-pages-servicos-index-js" */),
  "component---src-pages-servicos-laboratorio-alvo-leite-index-js": () => import("./../src/pages/servicos/laboratorio-alvo-leite/index.js" /* webpackChunkName: "component---src-pages-servicos-laboratorio-alvo-leite-index-js" */),
  "component---src-pages-servicos-manutencao-index-js": () => import("./../src/pages/servicos/manutencao/index.js" /* webpackChunkName: "component---src-pages-servicos-manutencao-index-js" */),
  "component---src-pages-servicos-programa-qualidade-do-leite-index-js": () => import("./../src/pages/servicos/programa-qualidade-do-leite/index.js" /* webpackChunkName: "component---src-pages-servicos-programa-qualidade-do-leite-index-js" */),
  "component---src-pages-sobre-index-js": () => import("./../src/pages/sobre/index.js" /* webpackChunkName: "component---src-pages-sobre-index-js" */)
}

